import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project61 from "../../../assets/Images/Slider/Slider7/project61.jpg";
import project62 from "../../../assets/Images/Slider/Slider7/project62.jpeg";
import project63 from "../../../assets/Images/Slider/Slider7/project63.jpeg";

const images = [
  {
    original: `${project61}`,
    thumbnail: `${project61}`,
  },
  {
    original: `${project62}`,
    thumbnail: `${project62}`,
  },
  {
    original: `${project63}`,
    thumbnail: `${project63}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
