import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project71 from "../../../assets/Images/Slider/Slider8/project71.jpg";
import project72 from "../../../assets/Images/Slider/Slider8/project72.jpg";
import project73 from "../../../assets/Images/Slider/Slider8/project73.jpg";

const images = [
  {
    original: `${project71}`,
    thumbnail: `${project71}`,
  },
  {
    original: `${project72}`,
    thumbnail: `${project72}`,
  },
  {
    original: `${project73}`,
    thumbnail: `${project73}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
