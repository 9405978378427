import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project41 from "../../../assets/Images/Slider/Slider5/project41.jpg";
import project42 from "../../../assets/Images/Slider/Slider5/project42.jpg";
import project43 from "../../../assets/Images/Slider/Slider5/project43.jpg";
import project44 from "../../../assets/Images/Slider/Slider5/project44.jpg";
import project45 from "../../../assets/Images/Slider/Slider5/project45.jpg";

const images = [
  {
    original: `${project41}`,
    thumbnail: `${project41}`,
  },
  {
    original: `${project42}`,
    thumbnail: `${project42}`,
  },
  {
    original: `${project43}`,
    thumbnail: `${project43}`,
  },
  {
    original: `${project44}`,
    thumbnail: `${project44}`,
  },
  {
    original: `${project45}`,
    thumbnail: `${project45}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
