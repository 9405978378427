import React from "react";
import ErrorPage from "../Components/Error";
const Error = () => {
  return (
    <div>
      <ErrorPage />
    </div>
  );
};

export default Error;
