import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project51 from "../../../assets/Images/Slider/Slider6/project51.jpg";
import project52 from "../../../assets/Images/Slider/Slider6/project52.jpg";
import project53 from "../../../assets/Images/Slider/Slider6/project53.jpg";

const images = [
  {
    original: `${project51}`,
    thumbnail: `${project51}`,
  },
  {
    original: `${project52}`,
    thumbnail: `${project52}`,
  },
  {
    original: `${project53}`,
    thumbnail: `${project53}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
