import React from "react";
import Hero from "./Hero";
import Descrioption from "./Descrioption";
import SliderWindows from "./SliderWindows";
import SliderDoors from "./SliderDoors";
import Aniversary from "./Aniversary";
const index = () => {
  return (
    <div>
      <Hero />
      <SliderWindows />
      <Aniversary />
      <Descrioption />
      <SliderDoors />
    </div>
  );
};

export default index;
