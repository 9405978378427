import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

const DescriptionWrapper = styled.div`
  h1 {
    color: #0e6495;
  }
`;

const Item = styled.div`
  background-color: #fff;
  border-radius: 8px;
  height: 200px;
  padding: 20px;
  border: 1px solid #0e6495;
  @media (max-width: 768px) {
    height: auto;
  }
  h2 {
    border-bottom: 1px solid #0e6495;
  }
  p {
    color: #646464;
  }
`;

const Descrioption = () => {
  return (
    <DescriptionWrapper>
      <Container style={{ maxWidth: 1300 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h1> - Vlerat</h1>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item>
              <h2>Përfeksioni</h2>
              <p>
                Një punë e përkryer dallohet në detaje. Teknologjia më e
                avancuar ndihmon Çeta Plast të krijojë produkte në formën më të
                mirë.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item>
              <h2>Pasioni</h2>
              <p>
                Për ne, prodhimi i profileve nuk është një punë, por një pasion.
                Kjo veçori na frymëzon dhe nxit produktivitetin tonë.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item>
              <h2>Kualiteti</h2>
              <p>
                Gjatësia e profileve është një vlerë shtesë që bën Çeta Plast
                prodhuesin kryesor të profileve të dritareve PVC në rajon dhe
                përtej tij.
              </p>
            </Item>
          </Grid>

          <Grid item xs={12} md={4}>
            <Item>
              <h2>Kompetenca</h2>
              <p>
                Prodhimi kërkon aftësi. Aftësitë kërkojnë kompetencë, ndërsa
                kompetenca na bën përgjegjës për të jetësuar misionin tonë.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item>
              <h2>Respekti</h2>
              <p>
                Nuk ka klient të vogël ose të madh për Çeta Plast. Çdo i
                interesuar meriton respekt të duhur. Është ky elementi që mbjell
                besim reciprok, siguri dhe mirëqenie.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item>
              <h2>Besueshmeria</h2>
              <p>
                Besimi i plotë në kompani dhe puna me pasion përfshin gamën e
                vlerave të Çeta Plast. Kjo është falë krijimit të një mjedisi ku
                çdo punonjës vlerësohet dhe shpërblehet edhe për kontributin më
                të vogël.
              </p>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </DescriptionWrapper>
  );
};

export default Descrioption;
