import React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import Slider from "./Slider1";
import Slider2 from "./Slider2";
import Slider3 from "./Slider3";
import Slider4 from "./Slider4";
import Slider5 from "./Slider5";
import Slider6 from "./Slider6";
import Slider7 from "./Slider7";
import Slider8 from "./Slider8";
import Slider9 from "./Slider9";

const BuildWrapper = styled.div`
  width: 100%;
  height: 100%;

  h1 {
    text-align: center;
    color: #fa981c;
  }
  h2 {
    text-align: center;
    font-weight: 400;
  }
  h3 {
    text-align: center;
    color: #fa981c;
  }
`;

const Item = styled.div``;

const Build = () => {
  return (
    <BuildWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h1>- Ndërtime të larta dhe të ulëta</h1>
            <h2>
              Përfundimi i punimeve në objektin banesorë “Çeta Residence” në
              qendër të qytetit të Pejës.
            </h2>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Çeta Residencë -</h3>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Ceta General Invest Group</p>
              <h4>Emri i projektit:</h4> <p>Çeta Residence</p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Përshkrim i shkurtër i punimeve që kemi kryer: Punimet e
                përgatitjes, punimet e betonit, punimet e armatimit, punimet e
                izolimit termik, punimet e tullave, punimet e gdhendjes, punimet
                e glazurimit, punimet e zbukurimit, punimet e ngjyrosjes,
                punimet e vendosjes së dyerve dhe dritareve, punimet e vendosjes
                së dyerve dhe dritareve, punimet e nivelimit, punimet e tavanit,
                punimet e fasadës, punimet metalike, punimet elektrike, punimet
                e instalimeve hidraulike, punimet mekanike, etj.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <Slider />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Constitutional court of Kosovo -</h3>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Gjykata Kushtetuese e Kosovës</p>
              <h4>Emri i projektit:</h4>
              <p>
                Rindërtimi i objektit ekzistues të Gjykatës Kushtetuese të
                Kosovës
              </p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Punët që kemi kryer janë punët përgatitore, punët e prishjes dhe
                pastrimit, struktura e çatisë, punët e metaleve të blerta, punët
                e gipsit dhe pikëllimit, punët e drurit, punët e dyshemeve,
                punët e sipërfaqes së murit, punët e drurit të dyerve dhe
                dritareve, punët e fasadës, punët e ekskavimit dhe mbushjes,
                punët e gjelbëritjes dhe mobiljeve jashtë, punët e gurit, punët
                e betonit, punët e rrugës së gurëve, punët e tualetit, punët në
                trotuarin e oborrit të manastirit, punët në trotuarin jashtë
                manastirit, punët elektrike, punët e instalimeve hidraulike,
                punët mekanike, dhe të tjera.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider2 />
            </Item>
          </Grid>

          <Grid item xs={12} md={12}>
            <Item>
              <h3>- KELKOS ENERGY SH.P.K -</h3>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>KELKOS ENERGY SH.P.K</p>
              <h4>Emri i projektit:</h4>{" "}
              <p>
                HEC LUMBARDHI 2- HIDROECTRAL PUNË CIVIL (KOSOVË) , HEC-Lumbardhi
                1-hidrocentrali; riparimi i kanalit të dëmtuar nga rrëshqitja e
                tokës
              </p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                HEC LUMBARDHI 2- HIDROcentrali PUNA QYTETARE: Punimet qe kemi
                bere jane Fillimi i ndertimit Marrja, depozitimi i sedimentimit
                dhe kalimi i peshkut. përfundimi i marrjes, rezervuari i
                sedimentimit dhe kalimi i peshkut dhe gati për instalimin e
                pajisjeve mekanike të çelikut fundi i rikultivimit të Marrjes,
                depozitës së sedimenimit dhe kalimit të peshkut Fillimi i
                ndërtimit të Power House përfundimi deri në nivelin 1.226,25 m -
                gati për instalimin e tubit të çelikut (dorëzuar dhe instaluar
                nga kompania e turbinave) përfundimi duke përfshirë traun e
                vinçit - fillimi i instalimit të vinçit të shtëpisë së energjisë
                Power House i mbyllur dhe pa pluhur - gati për instalimin E+M
                fundi i ndërtimit të Power House duke përfshirë pjesën e
                përparme dhe rikultivimin
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider3 />
            </Item>
          </Grid>

          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Kosovo Police -</h3>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Kosovo Police</p>
              <h4>Emri i projektit:</h4>{" "}
              <p>Ndërtimi i Stacionit Policor në Gjakovë</p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Punimet qe kemi bere jane: punime pergatitore, punime betoni,
                punime armature, punime termosizoluese, punime murature, punime
                suvatimi, punime sheshimi, ngjyrosje, punime dyshemeje, nivelim,
                punime tavanesh, punime fasade, punime metalike, punime
                elektrike, punime hidroinstaluese, punime mekanike etj.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider4 />
            </Item>
          </Grid>

          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Kosovo Police -</h3>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Kosovo Police</p>
              <h4>Emri i projektit:</h4>{" "}
              <p>Ndërtimi i stacionit policor në Radavc</p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Pershkrim i shkurter: Punimet qe kemi bere jane: punime
                pergatitore, punime betoni, punime armature, punime
                termosizoluese, punime murature, punime suvatimi, punime
                sheshimi, ngjyrosje, punime dyshemeje, nivelim, punime tavanesh,
                punime fasade, punime metalike, punime elektrike, punime
                hidroinstaluese, punime mekanike etj.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider5 />
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Austria Ambasy</h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Ambasada e Austrisë në Prishtinë</p>
              <h4>Emri i projektit:</h4> <p> Renovim i zyrës</p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Punimet qe kemi bere ne renovimin e ketyre objekteve jane:
                punime ndertimi, pllaka qeramike dhe guri, elektrike, dritare
                dhe dyer, bojatore dhe lyerje, punime dyshemeje, hidroizolim dhe
                ndertim tarracash, ngrohje, uje, sanitare dhe sanitare. mure dhe
                tavane gipsi, shkalle te reja, hyrja kryesore: rinovimi i
                shkalleve te jashtme dhe te tjera, siperfaqet
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider6 />
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Obelisk</h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Oda e Mjekëve të Kosovës</p>
              <h4>Emri i projektit:</h4> <p> Ndërtimi i Obeliskut - faza I</p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Punimet qe kemi bere jane punime pergatitore, punime dheu, varre
                dhe gure natyrale, punime betoni, punime tjegullash metalike ne
                obelisk.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider7 />
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h3>- UNDP</h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4>{" "}
              <p>PROGRAMI I ZHVILLIMIT I KOMBEVE TË BASHKUARA (UNDP)</p>
              <h4>Emri i projektit:</h4>{" "}
              <p>
                Dialogu ndërmjet komuniteteve përmes projektit gjithëpërfshirës
                të ruajtjes së trashëgimisë kulturore
              </p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Adoptimi/zhvendosja e urës dhe zbukurimi i zonës përreth pranë
                rr. Manastiri i kryeengjëjve në Prizren Rehabilitimi dhe
                zbukurimi i zonës së varrezave hebraike në Prishtinë
                Rehabilitimi dhe zbukurimi i urës së tabakut dhe rrethinave në
                Gjakovë Restaurimi/rehabilitimi dhe zbukurimi i shtëpisë muze të
                babait Shtjefën Gjeqovit në Janjevë Punimet e rinovimit të
                aneksit të kinemasë dhe hyrjes së Jusuf Gërvallës Investitori:
                Programi i Kombeve të Bashkuara për Zhvillim (UNDP)
              </p>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <Slider8 />
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h3>- Komuna e Istogut</h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <h4>Emri i klientit:</h4> <p>Komuna e Istogut</p>
              <h4>Emri i projektit:</h4>{" "}
              <p>Rregullimi i Memorialit "Ibrahim Rugova" faza IV</p>
              <h4>Pershkrimi:</h4>{" "}
              <p>
                Punët që kemi kryer janë punët e izolimit termik, punët e
                gurëve, punët e gipsit, punët e përpunimit, punët e ngjyrosjes,
                punët e dyshemeve, punët e nivelimit, punët e tavanit, punët e
                fasadës, punët elektrike, punët e instalimeve hidraulike, punët
                mekanike etj.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <Slider9 />
            </Item>
          </Grid>
        </Grid>
      </Container>
    </BuildWrapper>
  );
};

export default Build;
