import React from "react";
import ImagesDoors from "./ImagesDoors";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

const SliderWrapper = styled.div`
  margin: 80px 0px;
  h1 {
    color: #0e6495;
    margin-bottom: 80px;
  }
`;
const SliderDoors = () => {
  return (
    <SliderWrapper>
      <Container>
        <Grid cotnainer spacing={2}>
          <Grid item xs={12} md={12}>
            <h1>- Modelet e Dyerve </h1>
            <ImagesDoors />
          </Grid>
        </Grid>
      </Container>
    </SliderWrapper>
  );
};

export default SliderDoors;
