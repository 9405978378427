import React from "react";
import CookieConsent from "react-cookie-consent";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const CookiesWrapper = styled.div`
  button {
    background-color: #fa981c !important;
    color: #fff !important;
    padding: 12px 32px !important;
    border-radius: 5px !important;
    transition: ease-in-out 0.5s !important;
    &.active,
    &:hover {
      transition: ease-in-out 0.5s !important;
      background-color: #ccc !important;
      color: #000 !important;
    }
  }
  p {
    color: #fff;
  }
  a {
    text-transform: uppercase;
    color: #fa981c;
    text-decoration: none;
  }
`;

const index = () => {
  return (
    <CookiesWrapper>
      <CookieConsent>
        <p>
          This site uses cookies. By continuing to use this website, you agree
          to their use. See <Link to="/privacy">privacy policy</Link> for more
          info.
        </p>
      </CookieConsent>
    </CookiesWrapper>
  );
};

export default index;
