import React from "react";
import styled from "@emotion/styled";
import CompanyData from "../../assets/dummyData";
import { Container, Grid } from "@mui/material";

const ClientWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 120px;
  p {
    color: #898989;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-align: center;
  }
  h1 {
    color: #fa981c;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;

  svg {
    height: 200px !important;
  }

  img {
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: ease-in-out 0.3s;

    &:hover {
      transform: translate();
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      transform: translateY(-4px);
    }
  }
`;

const Clients = () => {
  return (
    <ClientWrapper>
      <Container style={{ maxWidth: 1300 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h4>awesome partners</h4>
            <h1>Our Clients</h1>
          </Grid>
          <Grid container spacing={2}>
            {CompanyData.map((data, index) => (
              <Grid item xs={12} md={4} key={data.id}>
                <Item>
                  <p>{data.CompanyName}</p>
                  <img src={data.img} alt={data.id} />
                </Item>
                {(index + 1) % 4 === 0 && <div style={{ width: "100%" }}></div>}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </ClientWrapper>
  );
};

export default Clients;
