import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import foto1 from "../../../assets/Images/Aniversary/DoorsSlider/foto1.jpg";
import foto2 from "../../../assets/Images/Aniversary/DoorsSlider/foto2.jpg";
import foto3 from "../../../assets/Images/Aniversary/DoorsSlider/foto3.jpg";
import foto4 from "../../../assets/Images/Aniversary/DoorsSlider/foto4.jpg";
import foto5 from "../../../assets/Images/Aniversary/DoorsSlider/foto5.jpg";
import foto6 from "../../../assets/Images/Aniversary/DoorsSlider/foto6.jpg";
import foto7 from "../../../assets/Images/Aniversary/DoorsSlider/foto7.jpg";
import foto8 from "../../../assets/Images/Aniversary/DoorsSlider/foto8.jpg";
import foto9 from "../../../assets/Images/Aniversary/DoorsSlider/foto9.jpg";
import foto10 from "../../../assets/Images/Aniversary/DoorsSlider/foto10.jpg";

const images = [
  {
    original: `${foto1}`,
    thumbnail: `${foto1}`,
  },
  {
    original: `${foto2}`,
    thumbnail: `${foto2}`,
  },
  {
    original: `${foto3}`,
    thumbnail: `${foto3}`,
  },
  {
    original: `${foto4}`,
    thumbnail: `${foto4}`,
  },
  {
    original: `${foto5}`,
    thumbnail: `${foto5}`,
  },
  {
    original: `${foto6}`,
    thumbnail: `${foto6}`,
  },
  {
    original: `${foto7}`,
    thumbnail: `${foto7}`,
  },
  {
    original: `${foto8}`,
    thumbnail: `${foto8}`,
  },
  {
    original: `${foto9}`,
    thumbnail: `${foto9}`,
  },
  {
    original: `${foto10}`,
    thumbnail: `${foto10}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
