import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Button } from "@mui/material";
import HeroMainImage from "../../assets/Images/DyerdheDritare/mainmain.jpg";
import { Link } from "react-router-dom";
const HeroWrapper = styled.div`
  background: url(${HeroMainImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    background: url(${HeroMainImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hero-position {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 768px) {
      
    }
  }
  .hero-content {
    background-color: rgba(255, 255, 255, 0.85);
    width: auto;
    height: auto;
    padding: 4rem;
    border-radius: 8px;
    @media (max-width: 768px) {
      width: calc(100% - 40px);
      height: 100%;
      padding: 20px;
    }

    .btn-1 {
      color: #0e6495;
      background-color: transparent;
      padding: 12px 32px;
      transition: ease-in-out 0.5s;
      border: 1px solid #0e6495;
      &.active,
      &:hover {
        transition: ease-in-out 0.5s;
        background-color: #0e6495;
        color: #fff;
        border: 1px solid #0e6495;
      }
    }
    .btn-2 {
      color: #0e6495;
      background-color: transparent;
      padding: 12px 32px;
      transition: ease-in-out 0.5s;
      margin-left: 20px;
      border: 1px solid #0e6495;
      &.active,
      &:hover {
        transition: ease-in-out 0.5s;
        background-color: #0e6495;
        color: #fff;
      }
    }
  }
`;

const Hero = () => {
  const backtoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <HeroWrapper>
      <Container style={{ maxWidth: 1300 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="hero-position">
              <div className="hero-content">
                <h1>Dyer dhe Dritare</h1>
                <Button
                  className="btn-1"
                  component={Link}
                  to="/kontakti"
                  variant="contained"
                  onClick={() => backtoTop()}
                >
                  Kontakti
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </HeroWrapper>
  );
};

export default Hero;
