import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project11 from "../../../assets/Images/Slider/Slider2/project11.jpg";
import project12 from "../../../assets/Images/Slider/Slider2/project12.jpg";
import project13 from "../../../assets/Images/Slider/Slider2/project13.jpg";
import project14 from "../../../assets/Images/Slider/Slider2/project14.jpeg";
import project15 from "../../../assets/Images/Slider/Slider2/project15.jpeg";

const images = [
  {
    original: `${project11}`,
    thumbnail: `${project11}`,
  },
  {
    original: `${project12}`,
    thumbnail: `${project12}`,
  },
  {
    original: `${project13}`,
    thumbnail: `${project13}`,
  },
  {
    original: `${project14}`,
    thumbnail: `${project14}`,
  },
  {
    original: `${project15}`,
    thumbnail: `${project15}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
