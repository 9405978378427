import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";

import project21 from "../../../assets/Images/Slider/Slider3/project21.jpeg";
import project22 from "../../../assets/Images/Slider/Slider3/project22.jpeg";
import project23 from "../../../assets/Images/Slider/Slider3/project23.jpeg";
import project24 from "../../../assets/Images/Slider/Slider3/project24.jpg";
import project25 from "../../../assets/Images/Slider/Slider3/project25.jpg";
import project26 from "../../../assets/Images/Slider/Slider3/project26.jpg";
import project27 from "../../../assets/Images/Slider/Slider3/project27.jpg";
import project28 from "../../../assets/Images/Slider/Slider3/project28.jpg";
import project29 from "../../../assets/Images/Slider/Slider3/project29.jpeg";

const images = [
  {
    original: `${project21}`,
    thumbnail: `${project21}`,
  },
  {
    original: `${project22}`,
    thumbnail: `${project22}`,
  },
  {
    original: `${project23}`,
    thumbnail: `${project23}`,
  },
  {
    original: `${project24}`,
    thumbnail: `${project24}`,
  },
  {
    original: `${project25}`,
    thumbnail: `${project25}`,
  },
  {
    original: `${project26}`,
    thumbnail: `${project26}`,
  },
  {
    original: `${project27}`,
    thumbnail: `${project27}`,
  },
  {
    original: `${project28}`,
    thumbnail: `${project28}`,
  },
  {
    original: `${project29}`,
    thumbnail: `${project29}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
