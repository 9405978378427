import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project31 from "../../../assets/Images/Slider/Slider4/project31.jpg";
import project32 from "../../../assets/Images/Slider/Slider4/project32.jpg";
import project33 from "../../../assets/Images/Slider/Slider4/project33.jpg";
import project34 from "../../../assets/Images/Slider/Slider4/project34.jpg";

const images = [
  {
    original: `${project31}`,
    thumbnail: `${project31}`,
  },
  {
    original: `${project32}`,
    thumbnail: `${project32}`,
  },
  {
    original: `${project33}`,
    thumbnail: `${project33}`,
  },
  {
    original: `${project34}`,
    thumbnail: `${project34}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
