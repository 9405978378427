import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import DorandWindows from "./Pages/DorandWindows";
import Buildings from "./Pages/Buildings";
import Error from "./Pages/Error";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Cookies from "./Components/Cokies";
import { useLocation } from "react-router-dom";
import SecondHeader from "./Components/Header/SecondHeader";
import SecondFooter from "./Components/Footer/SecondFooter";

const App = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/plast" ? <SecondHeader /> : <Header />}
      <Cookies />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rreth-nesh" element={<About />} />
        <Route path="/ndertimi" element={<Buildings />} />
        <Route path="/plast" element={<DorandWindows />} />
        <Route path="/kontakti" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
      {location.pathname === "/plast" ? <SecondFooter /> : <Footer />}
    </>
  );
};

export default App;
