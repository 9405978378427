import React from "react";
import HomeComponents from "../Components/Home";
import styled from "@emotion/styled";

const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Home = () => {
  return (
    <HomeWrapper>
      <HomeComponents />
    </HomeWrapper>
  );
};

export default Home;
