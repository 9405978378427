import React from "react";
import { Grid, Container, Button } from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
const ErrorWrapper = styled.div`
  padding-top: 100px;
  width: 100%;
  height: 100%;

  .btn {
    color: #fff;
    background-color: #0e6495;
    padding: 10px 28px;
    transition: ease-in-out 0.5s;
    &.active,
    &:hover {
      transition: ease-in-out 0.5s;
      background-color: #ccc;
      color: #0e6495;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  h2 {
    color: #ccc;
    font-size: 35px;
  }
  p {
    padding-bottom: 30px;
  }
`;

const index = () => {
  return (
    <ErrorWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Item>
              <h2>404</h2>
              <h1>You have found a secret place.</h1>
              <p>
                Unfortunately, this is only a 404 page. You may have mistyped
                the address, or the page has been moved to another URL.
              </p>
              <Button
                className="btn"
                component={Link}
                to="/"
                variant="contained"
              >
                take me back to homepage
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </ErrorWrapper>
  );
};

export default index;
