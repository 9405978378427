import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project81 from "../../../assets/Images/Slider/Slider9/project81.jpg";
import project82 from "../../../assets/Images/Slider/Slider9/project82.jpg";
import project83 from "../../../assets/Images/Slider/Slider9/project83.jpg";
import project84 from "../../../assets/Images/Slider/Slider9/project84.jpg";

const images = [
  {
    original: `${project81}`,
    thumbnail: `${project81}`,
  },
  {
    original: `${project82}`,
    thumbnail: `${project82}`,
  },
  {
    original: `${project83}`,
    thumbnail: `${project83}`,
  },
  {
    original: `${project84}`,
    thumbnail: `${project84}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}
export default Slider;
