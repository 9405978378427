import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import truckPNG from "../../assets/Images/Home/truck.png";

const OfferWrapper = styled.div`
  padding-top: 80px;
`;

const CotnainerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .btn {
    background-color: #fa981c;
    padding: 12px 32px;
    transition: ease-in-out 0.5s;
    color: #000;
    z-index: 1;
    svg {
      padding-left: 25px;
    }

    &:hover {
      transition: ease-in-out 0.5s;
      background-color: #ccc;
      color: #000;
    }
  }
`;

const WeOffer = () => {
  const backtoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <OfferWrapper>
      <div className="svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
        >
          <path
            fill="#000"
            d="M649.97 0h-99.94l49.88 54.12L649.97 0z"
            className="shape-fill"
          />
        </svg>
      </div>

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CotnainerItem>
              <div>
                <h2>Çfarë na dallon nga të tjerët?</h2>
                <p>
                  {" "}
                  Çfarë na bën të dalluar prej të tjerëve është angazhimi i
                  palëkundur i kompanisë sonë për cilësinë e lartë dhe shërbimin
                  e klientëve. Ne kemi një ekip të specializuar dhe të përvojuar
                  që është i përkushtuar ndaj zhvillimit të produkteve inovative
                  dhe ndërtimit të marrëdhënieve të besueshme me klientët.
                  Cilësia e produktit është për ne më e rëndësishme, dhe ne
                  sigurohemi që të gjitha produktet tona janë të ndërtuara me
                  kujdes dhe përmbushin standardet më të larta. Gjithashtu,
                  aftësia jonë për personalizim është një avantazh i madh. Ne e
                  kuptojmë se çdo klient ka nevoja të ndryshme, prandaj ofrojmë
                  mundësi të gjerë për të personalizuar produktet sipas
                  preferencave të tyre. Në përfundim, vlerat tona të cilësisë,
                  inovacionit, dhe marrëdhënieve të besueshme me klientët na
                  bëjnë të dalluar prej të tjerëve dhe na japin një avantazh
                  konkurrues në treg.
                </p>

                <Button
                  className="btn"
                  component={Link}
                  to="/rreth-nesh"
                  onClick={() => backtoTop()}
                >
                  Lexo me shum
                  <ChevronRightIcon />
                </Button>
              </div>
            </CotnainerItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <CotnainerItem>
              <LazyLoadImage
                src={truckPNG}
                style={{
                  height: "300px",
                }}
                alt="truck"
              />
            </CotnainerItem>
          </Grid>
        </Grid>
      </Container>
    </OfferWrapper>
  );
};

export default WeOffer;
