import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import AboutImage from "../../assets/Images/About/abou-us-image.jpg";

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const HeadWrapper = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 80px;
  background-image: url(${AboutImage});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 100%;
  }
  .containe-wrapper {
    background-color: rgba(255, 255, 255, 0.85);
    width: 400px;
    height: auto;
    padding: 4rem;
    border-radius: 8px;
    @media (max-width: 768px) {
      width: auto;
      background-color: rgba(255, 255, 255, 0.229);
    }
  }
  h1 {
    color: #fa981c;
  }
`;

const Item = styled.div`
  p {
    padding: 0px 30px;
    @media (max-width: 768px) {
      padding: 0px 0px;
    }
  }

  h2 {
    color: #fa981c;
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
const Information = () => {
  return (
    <InfoWrapper>
      <HeadWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={6}>
            <div className="containe-wrapper">
              <h1> ÇETA-General Invest Group SH.P.K</h1>

              <p>
                {" "}
                Kompania e ndërtimit ÇETA-General Invest Group SH.P.K, ushtron
                veprimtarinë e saj në ndërtim gati dy dekada. Kompania është
                themeluar me bashkimin e kapitalit të dy kompanive private
                N.P.N. "Çeta" dhe “A. C. Group – Planen & Bauen”, me pronarë
                Inxh. Dipl. Arch. Avdyl Çetaj dhe Suhadije Çetaj.
              </p>
            </div>
          </Grid>
        </Grid>
      </HeadWrapper>

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Item>
              <h2> - Rreth Nesh: </h2>
              <p>
                ÇETA-General Invest Group SH.P.K është shoqëri me përgjegjësi të
                kufizuar e regjistruar në vitin 2006 me numër të biznesit
                810298621. Kontributi i kompanisë nuk vjen vetëm përmes
                ndërtimit, por gjithashtu edhe punësimi mbetet hallkë e
                rendësishme e vizionit të kompanisë. Kompania jonë përbëhet nga
                një staf i kualifikuar, me eksperiencë, i cili përbëhet nga
                arkitekt, inxhinierë, ekonomistë, financjerë, teknike të
                specializuar, dhe mesatarisht 100 specialistë e punëtorë me
                përvojë në fushën e ndërtimit. Sot pas shumë vitesh ekperiencë e
                gjejmë vehtën mes emrave më aktivë në tregun kosovar në
                investim, ndërtim dhe projektim, duke iu përgjigjur kërkesave të
                klientëve me cilësi, korrektësi dhe shpejtësi. Ritmet e
                zhvillimit të kompanisë vërehen në investimet e kompanisë për
                moderinizimin e mjeteve, makinerive dhe paisjeve speciale për
                kryerjen e punimeve të ndërtimit. Dëshmi e rritjes së kompanisë
                janë projektet e mëdha që i janë besuar dhe realizimi i tyre me
                sukses në kohë dhe cilësi të garantuar nga kompania. Gjatë
                këtyre viteve të aktivitetit të saj, në fushën e ndërtimit të
                lartë, punëve civile dhe industriale, kompania ka punuar
                kryesisht në zbatimin e projekteve të financuara nga
                Institucionet vendore , Organizatat Jo Qeveritare vendore dhe të
                huaja, Investitor vendor dhe të huaj. Ne kujdesemi që të gjitha
                ndërtimet tona të jenë të sigurta, duke qënë se përdorim
                teknologjitë dhe teknikat e fundit, duke i garantuar edhe
                punonjësve tanë siguri maksimale gjatë punimeve.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h2>- Veprimtaria e kompanisë</h2>
              <p>
                Kompania “ÇETA – General Invest Group“sh.p.k aktivitetin kryesor
                të saj e ka në ndërtim, ndërtimet e larta dhe të ulëta,
                rregullimin e infrastrukturës, projektimin, mbikqyrjen. Stafi i
                kompanisë është i licensuar dhe çertifikuar për ofrimin e
                shërbimeve edhe në këto fusha si: vlerësimin e patundshmërive,
                vlerësimin e ndikimit në mjedisë (VNM), hartimin e elaborateve
                për mbrojtje nga zjarri (AMZ), raporte për siguri dhe shëndet në
                punë, raporte për llogarinë e energjisë efiçiente. Me qëllim të
                ofrimit të cilësisë sa më të lartë në ndërtimet tona Kompania ka
                zgjeruar aktivitetet e saj edhe me hapjen e fabrikës për
                prodhimin e betonit. Fabrika përveç kërkesës nga vet kompania
                për shërbim ajo ka kapacitet edhe për shitje të produkteve edhe
                për klientët të jashtëm. Cilësia e betonit të prodhuar është
                vërtetuar përmes analizave të ndryshme të kryera nga
                institucionet e ndryshme të çertifikuara ndërkombëtare. Njëherit
                kompania jonë prodhimin e ka të shtrirë vetëm në Regjionin e
                Pejës dhe ka për qëllim zgjerimin e mëtutjeshëm në tërë
                territorin e Kosovës. Sukseset e kompanisë nuk ndalen këtu,
                nevoja për shërbim cilësor dhe të shpejtë ka bërë që kompania të
                hap edhe fabrikën për prodhimin e dyerve dhe dritareve nga PVC.
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item>
              <h2>- Fokusi i Klientit</h2>
              <p>
                Qasja jonë drejtohet nga një fokus i palëkundur në kuptimin e
                plotë të nevojave të klientëve dhe përmbushjen e premtimeve
                tona, pavarësisht nga shkalla ose kompleksiteti i sfidës në
                fjalë. Duke punuar në bashkëpunim me klientët tanë, ne
                zhvillojmë ekipe të veçanta dhe zgjidhje nga koncepti përmes çdo
                faze të projektit deri në përfundim, duke siguruar ekspertizën
                dhe burimet më të mira të vendosura për të arritur kohën e
                kërkuar, koston, cilësinë, rezultatet e sigurisë dhe
                qëndrueshmërisë.
              </p>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </InfoWrapper>
  );
};

export default Information;
