import React from "react";
import styled from "@emotion/styled";
import { Grid, Container } from "@mui/material";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 80px;

  h1 {
    color: #fa981c;
  }
`;

const Item = styled.div`
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: left;

  svg {
    width: 50px;
    height: 50px;
    color: #fa981c;
  }

  .text-icon {
    padding-left: 40px;

    a {
      text-decoration: none;
      color: #000;
      font-size: 20px;
    }
  }
`;

const Location = styled.div``;
const ContactHero = () => {
  const mapStyles = {
    height: "400px",
    width: "100%",
    marginTop: "80px",
  };

  const defaultCenter = {
    lat: 42.66484983806095,
    lng: 21.162784409874877,
  };
  return (
    <ContactWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h1>- Na Kontaktoni</h1>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>Thirrje direkte:</h3>
            <Item>
              <PhonelinkRingIcon />
              <div className="text-icon">
                <a href="tel:+383 44 232167">+383 44 232167</a>
                <br />
                <a href="tel:+383 44 394853">+383 44 394853</a>
              </div>
            </Item>
            <Item>
              <EmailIcon />
              <div className="text-icon">
                <a href="mailto:info@ceta-gig.com?body=...">
                  info@ceta-gig.com
                </a>
              </div>
            </Item>
            <Item>
              <LocationOnIcon />
              <div className="text-icon">
                <a
                  href="https://www.google.com/maps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LOCATION
                </a>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Location>
              <LoadScript googleMapsApiKey="AIzaSyCZTYWpEwo7PXoOoQwKumwcmO5-yRhofXM">
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={15}
                  center={defaultCenter}
                ></GoogleMap>
              </LoadScript>
            </Location>
          </Grid>
        </Grid>
      </Container>
    </ContactWrapper>
  );
};

export default ContactHero;
