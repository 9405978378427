import React from "react";
import { Grid, Container } from "@mui/material";
import styled from "@emotion/styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import recycle from "../../assets/Images/Aniversary/recycle.png";
import warranty from "../../assets/Images/Aniversary/warranty.png";
import eficasity from "../../assets/Images/Aniversary/eficasity.png";

const AniversaryWrapper = styled.div`
  margin-bottom: 80px;
  h3 {
    color: #0e6495;
    text-align: center;
  }
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
`;

const Aniversary = () => {
  return (
    <AniversaryWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <LazyLoadImage
              src={recycle}
              alt="recycle"
              style={{
                width: 300,
                paddingTop: 50,
              }}
            />
            <h3
              style={{
                paddingTop: 25,
              }}
            >
              Materialet e Reciklueshme
            </h3>
          </Grid>
          <Grid item xs={12} md={4}>
            <LazyLoadImage src={warranty} alt="warranty" />
            <h3>10 vite Garancion</h3>
          </Grid>
          <Grid item xs={12} md={4}>
            <LazyLoadImage src={eficasity} alt="eficasity" />
            <h3>Energji Efikase</h3>
          </Grid>
        </Grid>
      </Container>
    </AniversaryWrapper>
  );
};

export default Aniversary;
