import React from "react";
import AboutComponents from "../Components/About";

const About = () => {
  return (
    <div>
      <AboutComponents />
    </div>
  );
};

export default About;
