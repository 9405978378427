import React from "react";
import styled from "@emotion/styled";
import DW from "../Components/DyerDritare";
const DorWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 100px;
`;

const DorandWindows = () => {
  return (
    <DorWrapper>
      <DW />
    </DorWrapper>
  );
};

export default DorandWindows;
