import React from "react";
import Banner from "./Banner";
import Infomartive from "./Infomartive";
import WeOffer from "./WeOffer";
import CLients from "./Clients";

const HomeComponents = () => {
  return (
    <div>
      <Banner />
      <Infomartive />
      <WeOffer />
      <CLients />
    </div>
  );
};

export default HomeComponents;
