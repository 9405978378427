import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Images/Logo/primary.png";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const HeaderWrapper = styled.div`
  position: fixed;
  margin: 0 auto;
  width: 100%;
  height: 100px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 9;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 100px;
    z-index: 5;
  }
  ul {
    display: flex;
    align-items: center;

    li {
      list-style: none;

      a {
        text-decoration: none;
        color: #000;
        margin-left: 25px;
        transition: ease-in-out 0.25s;
        &.active {
          color: #fa981c;
          border-bottom: 2px solid #000;
        }
        &:hover {
          border-bottom: 2px solid #fa981c;
        }

        @media (max-width: 768px) {
          margin-left: 0px;
          font-size: 20px;
        }
      }
    }

    @media (max-width: 768px) {
      border: 1px solid #1a1a22;
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: -18px;
      right: 0;
      background-color: #fff;
      z-index: 1;
      width: 100%;
      height: 110vh;

      li {
        text-align: center;
        margin: 10px 0;
      }
    }

    &.active {
      display: flex;
    }
  }
  .btn {
    color: #fff;
    background-color: #fa981c;
    padding: 10px 28px;
    transition: ease-in-out 0.5s;
    &.active,
    &:hover {
      transition: ease-in-out 0.5s;
      background-color: #ccc;
      color: #fa981c;
    }
  }
  .menu-icon {
    position: absolute;
    z-index: 2;
    right: 30px;
    top: 40px;
    display: none;
    cursor: pointer;
    color: #fa981c;
    order: 3;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const Header = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const backtoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <HeaderWrapper>
      <Container>
        <Item>
          <NavLink to="/" onClick={() => backtoTop()}>
            <img src={logo} alt="logo" />
          </NavLink>
          <div className="menu-icon" onClick={toggleMenu}>
            {menuVisible ? <CloseIcon /> : <MenuIcon />}
          </div>

          <ul
            className={`nav-links ${menuVisible ? "active" : ""}`}
            onClick={() => {
              backtoTop();
              toggleMenu();
            }}
          >
            <li>
              <NavLink path="/" to="/">
                Kryefaqja
              </NavLink>
            </li>
            <li>
              <NavLink to="/rreth-nesh">Rreth nesh</NavLink>
            </li>
            <li>
              <NavLink to="/ndertimi">Projektet Ndertimore</NavLink>
            </li>
            <li>
              <NavLink to="/plast">Dyer dhe Dritare</NavLink>
            </li>
            <li>
              <Button
                className="btn"
                component={NavLink}
                to="/kontakti"
                variant="contained"
                onClick={() => backtoTop()}
              >
                Kontakti
              </Button>
            </li>
          </ul>
        </Item>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
