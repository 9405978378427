import logo1 from "./Images/ClientsLogo/kag.png";
import logo2 from "./Images/ClientsLogo/kelkos-logoo-01.png";
import logo3 from "./Images/ClientsLogo/ksgroup.png";
import logo4 from "./Images/ClientsLogo/salamander.png";
import logo5 from "./Images/ClientsLogo/sharrcem.png";
import logo6 from "./Images/ClientsLogo/veka.png";

const dummyData = [
  {
    id: 1,
    CompanyName: "KAG",
    img: [logo1],
  },
  {
    id: 2,
    CompanyName: "KELKOS",
    img: [logo2],
  },
  {
    id: 3,
    CompanyName: "REPUBLIKA E KOSOVES",
    img: [logo3],
  },
  {
    id: 4,
    CompanyName: "SALAMANDER",
    img: [logo4],
  },
  {
    id: 5,
    CompanyName: "SHARR CEM",
    img: [logo5],
  },
  {
    id: 6,
    CompanyName: "VEKA",
    img: [logo6],
  },
];

export default dummyData;
