import React from "react";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const InfoWrapper = styled.div`
  padding-top: 80px;
  .paper-wrapper {
    width: 100%;
    height: 100%;
  }
  h1 {
    font-size: 45px;
    line-height: 0px;
    padding: 50px 30px;
    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 20px;
      padding: 0px 0px;
    }
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  .item-wrapper {
    padding: 20px;
    border-radius: 10px;
    svg {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      path {
        color: #fa981c;
      }
    }

    h2 {
      display: flex;
      align-items: center;
    }
  }
`;

const Infomartive = () => {
  return (
    <InfoWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h1>Pse ÇETA GIG ?</h1>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <div className="item-wrapper">
                <h2>
                  <CheckCircleOutlineIcon />
                  Eksperienca dhe Ekspertiza
                </h2>
                <p>
                  Ne gëzojmë një histori të pasur në fushën e ndërtimit dhe kemi
                  një ekip të specializuar me përvojë të gjatë dhe njohuri të
                  thella në këtë fushë. Kjo na lejon të ofrojmë zgjidhje të
                  personalizuara dhe të jemi të përgatitur për çdo sfidë që
                  projekti juaj mund të sjellë.
                </p>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <div className="item-wrapper">
                <h2>
                  <CheckCircleOutlineIcon />
                  Diversiteti i Projekteve
                </h2>
                <p>
                  Ne kemi ndërtuar një gamë të gjerë të projekteve, duke
                  përfshirë apartamente, vila, ndërtime komerciale, dhe ndërtime
                  të tjera të specializuara. Për ne, çdo projekt është një sfidë
                  unike që ne e përballojmë me pasion dhe përkushtim.
                </p>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <div className="item-wrapper">
                <h2>
                  <CheckCircleOutlineIcon />
                  Cilësia e lartë
                </h2>
                <p>
                  Cilësia është parësore për ne. Përdorimi i materialeve të
                  larta dhe zbatimi i praktikave të ndërtimit të avancuara
                  sigurojnë që projekti juaj të jetë më i miri.
                </p>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <div className="item-wrapper">
                <h2>
                  <CheckCircleOutlineIcon />
                  Partneriteti dhe Bashkëpunimi
                </h2>
                <p>
                  Ne e vlerësojmë partneritetin dhe bashkëpunimin me klientët
                  tanë. Jemi të gatshëm të dëgjojmë idetë dhe vizionin tuaj dhe
                  të punojmë së bashku për të arritur qëllimet e projektin tuaj.
                </p>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Item>
              <div className="item-wrapper">
                <h2>
                  <CheckCircleOutlineIcon />
                  Mjedisi dhe Qëndrueshmëria
                </h2>
                <p>
                  Ne kujdesemi për mjedisin dhe kemi angazhuar resurse për të
                  zvogëluar ndikimin tonë në të. Projekte tona janë të
                  dizajnuara për të përmbushur standardet më të larta të
                  qëndrueshmërisë dhe të kujdesen për mjedisin.
                </p>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </InfoWrapper>
  );
};

export default Infomartive;
