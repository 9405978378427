import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import logo from "../../assets/Images/Logo/plast-pattern.png";
import styled from "@emotion/styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const FooterWrapper = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  border-top: 1px solid #ccc;
  padding: 20px 0px;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 220px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  ul {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    li {
      list-style: none;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      span {
        font-size: 20px;
        font-weight: 200;
        text-transform: uppercase;
      }
      a {
        font-size: 20px;
        font-weight: 200;
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        transition: ease-in-out 0.3s;
        &:hover {
          color: #0e6495;
        }
      }
    }
  }
  p {
    font-size: 20px;
    font-weight: 200;
    line-height: 4px;
  }

  img {
    height: 250px;
  }
  h3 {
    font-size: 22px;
    font-weight: 200;
    line-height: 0px;
  }
`;

const SocialWrapper = styled.div`
  width: 100%;
  height: 70px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  text-align: center;

  h3 {
    font-size: 18px;
    text-transform: uppercase;

    span {
      font-size: 15px;
    }
  }
  a {
    text-decoration: none;
    color: #000;
  }
`;

const SocialItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      list-style: none;
    }
  }
  .icons-wrapper {
    padding-left: 20px;
    display: flex;
    svg {
      color: #000;
      width: 30px;
      height: 30px;
      transition: ease-in-out 0.3s;
      &:hover {
        transform: translateY(-4px);
        color: #0e6495;
      }
    }
  }
`;

const ItemPhone = styled.div`
  width: 100%;
  height: 100px;
  @media (max-width: 768px) {
    height: 100%;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
    }
    p {
      line-height: 4px;
      font-size: 20px;
      font-weight: 200;
    }
    li {
      list-style: none;
      font-size: 20px;
      font-weight: 200;
      line-height: 4px;
      padding-bottom: 20px;
      a {
        text-decoration: none;
        color: #000;
        transition: ease-in-out 0.3s;
        &:hover {
          color: #fa981c;
        }
      }
    }
  }
`;

const SecondFooter = () => {
  const backtoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <FooterWrapper>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Item>
                <Link onClick={() => backtoTop()} to="/">
                  <LazyLoadImage src={logo} alt="footer-logo" />
                </Link>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item>
                <ul onClick={() => backtoTop()}>
                  <li>
                    <Link to="/"> # kryefaqja</Link>
                  </li>
                  <li>
                    <Link to="/rreth-nesh"> # Rreth Nesh</Link>
                  </li>
                  <li>
                    <Link to="/ndertimi">
                      {" "}
                      # Ndërtime të larta dhe të ulëta
                    </Link>
                  </li>
                  <li>
                    <Link to="/plast"> # Dyer dhe dritare</Link>
                  </li>
                  <li>
                    <Link to="/kontakt"> # Kontakti</Link>
                  </li>
                </ul>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item>
                <ul>
                  <li>
                    <p> Selia kryesore: </p>
                    <a href="tel:+383 44 232167 ">+383 44 232167</a>,
                    <a href="tel:+383 44 394853"> +383 44 394853</a>
                  </li>
                  <li>
                    <p>Zyra e Pejës:</p>
                    <a href="tel:+383 44 504101">+383 44 504101</a>,
                    <a href="tel:+383 45 101003">+383 45 101003</a>
                  </li>
                </ul>
              </Item>
            </Grid>
            <Grid item xs={12} md={12}>
              <ItemPhone>
                <ul>
                  <li>
                    <p>
                      Dobrusha: <br />
                    </p>
                    <a href="tel: +383 44 723297"> +383 44 723297</a>,
                    <a href="tel:+383 45 101003">+383 45 101003</a>
                  </li>
                  <li>
                    <p>
                      Prodhimi i betonit: <br />
                    </p>
                    <a href="tel:+393 44 138 988">+393 44 138 988</a>,
                    <a href="tel:+383 44 177781">+383 44 177781</a>
                  </li>
                  <li>
                    <p>
                      Dyert dhe dritaret PVC: <br /> ,
                    </p>
                    <a href="tel:+383 45 101003">+383 45 101003</a>
                    <a
                      href="tel:+383 45 611
                      281"
                    >
                      383 45 611 281
                    </a>
                  </li>
                </ul>
              </ItemPhone>
            </Grid>
          </Grid>
        </Container>
      </FooterWrapper>
      <SocialWrapper>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <SocialItemWrapper>
                <div>
                  <h3>
                    © ALL RIGHT RESERVED{" "}
                    <span>
                      <Link to="/">CETa general investment group</Link>
                    </span>{" "}
                  </h3>
                </div>
                <ul>
                  <li>
                    <h3>Follow US</h3>
                  </li>
                  <div className="icons-wrapper">
                    {" "}
                    <li>
                      <Link
                        to="https://www.facebook.com/ceta.gig/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FacebookIcon />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/cetagig/?hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramIcon style={{ paddingLeft: 20 }} />
                      </Link>
                    </li>
                  </div>
                </ul>
              </SocialItemWrapper>
            </Grid>
          </Grid>
        </Container>
      </SocialWrapper>
    </>
  );
};

export default SecondFooter;
