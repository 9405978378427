import React from "react";
import styled from "@emotion/styled";
import Information from "./Information";

const AboutusWrapper = styled.div`
  padding-top: 100px;
`;

const AboutComponents = () => {
  return (
    <AboutusWrapper>
      <Information />
    </AboutusWrapper>
  );
};

export default AboutComponents;
