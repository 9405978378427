import ImageGallery from "react-image-gallery";
import React from "react";
import "./slider.scss";
import project1 from "../../../assets/Images/Slider/Slider1/project1.jpg";
import project2 from "../../../assets/Images/Slider/Slider1/project2.jpg";
import project3 from "../../../assets/Images/Slider/Slider1/project3.jpg";
import project4 from "../../../assets/Images/Slider/Slider1/project4.jpg";
import project5 from "../../../assets/Images/Slider/Slider1/project5.jpg";
import project6 from "../../../assets/Images/Slider/Slider1/project6.jpg";
import project7 from "../../../assets/Images/Slider/Slider1/project7.jpg";
import project8 from "../../../assets/Images/Slider/Slider1/project8.jpg";
import project9 from "../../../assets/Images/Slider/Slider1/project9.jpg";

const images = [
  {
    original: `${project1}`,
    thumbnail: `${project1}`,
  },
  {
    original: `${project2}`,
    thumbnail: `${project2}`,
  },
  {
    original: `${project3}`,
    thumbnail: `${project3}`,
  },
  {
    original: `${project4}`,
    thumbnail: `${project4}`,
  },
  {
    original: `${project5}`,
    thumbnail: `${project5}`,
  },
  {
    original: `${project6}`,
    thumbnail: `${project6}`,
  },
  {
    original: `${project7}`,
    thumbnail: `${project7}`,
  },
  {
    original: `${project8}`,
    thumbnail: `${project8}`,
  },
  {
    original: `${project9}`,
    thumbnail: `${project9}`,
  },
];

class Slider extends React.Component {
  render() {
    return <ImageGallery items={images} style={{ height: 400 }} />;
  }
}
export default Slider;
