import React from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import HomeImage from "../../assets/Images/Home/image-home.jpeg";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const HomeWrapper = styled.div`
  width: 100%;
  height: 102vh;
  background: url(${HomeImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    background-position: right;
  }
`;

const Item = styled.div`
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  @media (max-width: 768px) {
    height: 90vh;
  }
  .btn {
    background-color: #fa981c;
    padding: 12px 32px;
    transition: ease-in-out 0.5s;
    color: #000;
    z-index: 1;
    svg {
      padding-left: 25px;
    }

    &:hover {
      transition: ease-in-out 0.5s;
      background-color: #ccc;
      color: #000;
    }
    &:last-child {
      margin-left: 15px;
    }
  }
`;

const Banner = () => {
  return (
    <HomeWrapper>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Item>
              <Button className="btn" component={Link} to="/ndertimi">
                Ndertimi
                <ChevronRightIcon />
              </Button>
              <Button className="btn" component={Link} to="/plast">
                Plast
                <ChevronRightIcon />
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </HomeWrapper>
  );
};

export default Banner;
