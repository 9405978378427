import React from "react";
import styled from "@emotion/styled";
import BuildingComponent from "../Components/Buildings";

const BuildingWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 130px;
`;

const Buildings = () => {
  return (
    <BuildingWrapper>
      <BuildingComponent />
    </BuildingWrapper>
  );
};

export default Buildings;
