import React from "react";
import styled from "@emotion/styled";
import ContactHero from "./ContactHero";

const ContactWrapper = styled.div`
  padding-top: 130px;
`;

const index = () => {
  return (
    <ContactWrapper>
      <ContactHero />
    </ContactWrapper>
  );
};

export default index;
